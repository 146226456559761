import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { Grid } from '@sumup/circuit-ui/legacy';
import { Theme } from '@sumup/design-tokens';

import ColumnLayoutStack from '../../ColumnLayoutStack';
import { Column, Alignments, ImagePreset } from '../../../interfaces';

const slideBaseStyles = (): SerializedStyles => css`
  flex-shrink: 0;
  touch-action: manipulation;
`;

const slideMobileStyles = ({
  theme,
}: {
  theme?: Theme;
}): SerializedStyles => css`
  ${theme.mq.untilKilo} {
    padding-right: 20% !important;
  }
`;

const slideActiveStyles = ({
  theme,
  isActive,
}: {
  theme?: Theme;
  isActive?: boolean;
}): SerializedStyles =>
  isActive &&
  css`
    ${theme.mq.untilKilo} {
      margin-right: -16%;
    }
    @media (max-width: 410px) {
      margin-right: -8%;
    }
    @media (max-width: 370px) {
      margin-right: 0;
    }
  `;

const StyledGrid = styled(Grid)(
  slideBaseStyles,
  slideMobileStyles,
  slideActiveStyles,
);

export interface SlideProps {
  item?: Column[];
  isActive?: boolean;
  fullWidthButtons?: boolean;
  alignAllButtons?: boolean;
  index?: number;
  alignments?: Alignments;
  imagePreset?: ImagePreset;
  isFirstSectionWithoutHeadline?: boolean;
}

const Slide: FC<SlideProps> = ({
  fullWidthButtons,
  alignAllButtons,
  index,
  isActive,
  item,
  alignments,
  imagePreset,
  isFirstSectionWithoutHeadline,
}) => {
  if (isEmpty(item)) {
    return null;
  }

  return (
    <StyledGrid isActive={isActive}>
      <ColumnLayoutStack
        columns={item}
        fullWidthButtons={fullWidthButtons}
        alignAllButtons={alignAllButtons}
        index={index}
        alignments={alignments}
        imagePreset={imagePreset}
        isFirstSectionWithoutHeadline={isFirstSectionWithoutHeadline}
      />
    </StyledGrid>
  );
};

export default React.memo(Slide);
