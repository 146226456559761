import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { Column, Alignments, ImagePreset } from '../../interfaces';

import Slide from './components/Slide';

import SplitLayoutSlider from '~/shared/components/SplitLayoutSlider';

export interface ColumnLayoutSliderProps {
  slides?: Column[][];
  fullWidthButtons?: boolean;
  alignAllButtons?: boolean;
  index?: number;
  alignments?: Alignments;
  imagePreset?: ImagePreset;
  isFirstSectionWithoutHeadline?: boolean;
}

export const ColumnLayoutSlider: FC<ColumnLayoutSliderProps> = ({
  slides,
  fullWidthButtons,
  alignAllButtons,
  index,
  alignments,
  imagePreset,
  isFirstSectionWithoutHeadline,
}) => {
  if (isEmpty(slides)) {
    return null;
  }

  return (
    <SplitLayoutSlider isWide={true} items={slides}>
      <Slide
        fullWidthButtons={fullWidthButtons}
        alignAllButtons={alignAllButtons}
        index={index}
        alignments={alignments}
        imagePreset={imagePreset}
        isFirstSectionWithoutHeadline={isFirstSectionWithoutHeadline}
      />
    </SplitLayoutSlider>
  );
};
