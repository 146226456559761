import React, { cloneElement } from 'react';
import DubStep from 'dub-step';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CarouselComposer } from '@sumup/circuit-ui';
import { focusVisible } from '@sumup/circuit-ui/legacy';

const { NextButton, PrevButton } = CarouselComposer;

const buttonStyles = ({ theme }) => css`
  ${theme.mq.untilMega} {
    display: none;
  }
  width: ${theme.spacings.peta};
  height: ${theme.spacings.peta};
  align-items: center;
`;

const StyledNextButton = styled(NextButton)(buttonStyles);
const StyledPrevButton = styled(PrevButton)(buttonStyles);

const wrapperStyles = ({ theme, isWide }) => css`
  ${theme.mq.untilMega} {
    padding: ${theme.spacings.peta} ${theme.spacings.bit};
  }
  ${theme.mq.mega} {
    padding: ${theme.spacings.peta}
      ${isWide ? theme.spacings.bit : theme.spacings.tera};
  }
  width: 100%;
  overflow: hidden;
`;

const Wrapper = styled('div')(wrapperStyles);

const slideWrapperStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.mq.untilMega} {
    justify-content: center;
  }
`;

const SlideWrapper = styled('div')(slideWrapperStyles);

const barStyles = ({ theme }) => css`
  display: block;
  height: ${theme.spacings.kilo};
  width: ${theme.spacings.kilo};
  border-radius: ${theme.borderRadius.pill};
  background-color: var(--cui-fg-placeholder);
  transition:
    width ${theme.transitions.slow},
    background-color ${theme.transitions.slow};

  button:hover & {
    background-color: var(--cui-fg-placeholder-hovered);
  }

  button:active & {
    background-color: var(--cui-fg-placeholder-pressed);
  }

  [aria-current='true'] & {
    width: ${theme.spacings.peta};
    background-color: var(--cui-fg-accent);
  }

  [aria-current='true']:hover & {
    background-color: var(--cui-fg-accent-hovered);
  }

  [aria-current='true']:active & {
    background-color: var(--cui-fg-accent-pressed);
  }
`;

const Bar = styled('span')(barStyles);

const barWrapperStyles = ({ theme }) => css`
  padding: ${theme.spacings.byte};
  background: none;
  border: 0;
  border-radius: ${theme.borderRadius.pill};
  cursor: pointer;
`;

const BarWrapper = styled('button')(barWrapperStyles, focusVisible('inset'));

const BarWithWrapper = ({ isActive, ...other }) => (
  <BarWrapper {...other} aria-current={isActive}>
    <Bar />
  </BarWrapper>
);

const progressBarStyles = ({ theme }) => css`
  margin: ${theme.spacings.mega} auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ProgressBar = styled('div')(progressBarStyles);

const outerCarouselStyles = ({ theme }) => css`
  width: 75%;
  overflow: hidden;

  ${theme.mq.untilMega} {
    width: 85%;
  }

  ${theme.mq.untilKilo} {
    width: 100%;
  }
`;

const CarouselOuterWrapper = styled('div')(outerCarouselStyles);

const innerCarouselStyles = ({ step, theme }) => css`
  display: flex;
  transform: translate3d(${-step * 100}%, 0, 0);
  transition: all ${theme.transitions.slow};
`;

const CarouselInnerWrapper = styled('div')(innerCarouselStyles);

function SplitLayoutSlider({ items, children, isWide = false }) {
  if (isEmpty(items)) {
    return null;
  }

  return (
    <DubStep key={items.length} cycle swipe total={items.length}>
      {({ step, Next, Previous, StepIndex, getStepProps }) => (
        <Wrapper isWide={isWide}>
          <SlideWrapper>
            <Previous variant="primary" component={StyledPrevButton}>
              Previous
            </Previous>
            <CarouselOuterWrapper>
              <CarouselInnerWrapper {...getStepProps()} step={step}>
                {items.map((item, index) =>
                  cloneElement(children, {
                    isActive: index === step,
                    key: index,
                    item,
                  }),
                )}
              </CarouselInnerWrapper>
            </CarouselOuterWrapper>
            <Next variant="primary" component={StyledNextButton}>
              Next
            </Next>
          </SlideWrapper>
          <ProgressBar>
            {items.map((item, index) => (
              <StepIndex
                step={index}
                key={index}
                component={BarWithWrapper}
                isActive={index === step}
              />
            ))}
          </ProgressBar>
        </Wrapper>
      )}
    </DubStep>
  );
}

SplitLayoutSlider.propTypes = {
  items: PropTypes.array,
  children: PropTypes.node,
  isWide: PropTypes.bool,
};

export default SplitLayoutSlider;
